import React from 'react'

export const Copyright = () => {
  return (
    <div className='bg-color-d text-center'>
        <div className='container'>
            <div className='row'>
                <div className='col'>
                    <div className='color-b p-3'>Copyright &copy; 2022 geniusHubBD | All rights reserved</div>
                </div>
            </div>
        </div>
    </div>
  )
}
