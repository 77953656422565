import React from 'react'
import { Link } from 'react-router-dom'
import error404 from '../../assets/frontend/img/common/404.png'

export const Error = () => {
  return (
    <div className='text-center'>
        <div className='container'> 
            <div className='row'>
                <div className='col'>
                    <div className='mt-5'>
                        <img className='img-fluid' src={error404} alt='404' />
                        <div>
                            <Link to='/'>GeniusHubBD</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
