import React from 'react'
import { Header } from './Header'
import Navbar from '../../layouts/frontend/Navbar'
import { Features } from './Features'
import { Setps } from './Setps'
import { Services } from './Services'
import { Banner } from './Banner'
import { Achievements } from './Achievements'
import { Footer } from '../../layouts/frontend/Footer'
import { Copyright } from '../../layouts/frontend/Copyright'

const Home = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <Features />
      <Setps />
      <Services />
      <Banner />
      <Achievements />
      <Footer />
      <Copyright />
    </div>
  )
}

export default Home
