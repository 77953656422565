import React from 'react'

import atom from '../../assets/frontend/img/features/atom.png'
import conference from '../../assets/frontend/img/features/conference.png'
import coin from '../../assets/frontend/img/features/coin.png'
import { Feature } from './col/Feature'

export const Features = () => {
  return (
    <div>
        <div className='container py-5'>
            <div className='row text-center'>
                <Feature icon={atom} title="Innovative Solutions" description="Auto-assign tasks, send Slack messages, and much more. Now power up with hundreds of new templates to help you get started" />
                <Feature icon={conference} title="Experienced Team" description="Auto-assign tasks, send Slack messages, and much more. Now power up with hundreds of new templates to help you get started" />
                <Feature icon={coin} title="Flexible Pricing" description="Auto-assign tasks, send Slack messages, and much more. Now power up with hundreds of new templates to help you get started" />
            </div>
        </div>
    </div>
  )
}
