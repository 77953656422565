import React from 'react'

export const Step = (props) => {
  return (
    <div className='col-md-4 rounded'>
        <div className='card border-0' style={{ background: '#E5E7EB' }}>
            <div className='card-body'>
                <h3 className='bg-info d-inline-block px-3 py-1 rounded-circle'>{ props.id }</h3>
                <h4><strong>{ props.title.split(' ')[0] }</strong> { props.title.replace(props.title.split(' ')[0], '') }</h4>
            </div>
        </div>
    </div>
  )
}
