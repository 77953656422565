import React from 'react'

export const Banner = () => {
  return (
    <div className='banner-section text-light'>
        <div className='container'>
            <div className='row'>
                <div className='col'>
                    <div className='banner-content-position'>
                        <h2>Convert you ideas into a great product!</h2>
                        <h2>Convert you ideas into a great product!</h2>
                        <p>Helping Women Meet All of their Health Care Needs</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
