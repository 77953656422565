import React from 'react'

export const Achivement = (props) => {
  return (
    <div className='col-md-2'>
        <div className='card'>
            <div className='card-body bg-color-b rounded achivement-effect'>
                <h2><strong>{ props.total }</strong></h2>
                <p>{ props.title }</p>
            </div>
        </div>
    </div>
  )
}
