import React from 'react'
import Navbar from '../../layouts/frontend/Navbar'
import { SubHeader } from './SubHeader'
import startup from '../../assets/frontend/img/about/startup.png'
import { Footer } from '../../layouts/frontend/Footer'
import { Team } from './card/Team'
import person from '../../assets/frontend/img/common/person.jpg'
import { Copyright } from '../../layouts/frontend/Copyright'

export const About = () => {
  return (
    <div>
        <Navbar />
        <SubHeader title='About Us' />
        <div className='container mt-5'>
            <div className='row'>
                <div className='col-md-6 text-center'>
                    <img className='img-fluid' src={startup} alt='startup' />
                </div>
                <div className='col-md-6'>
                    <div className="mt-5">
                        <h2 className='fw-bold mb-4'>Lorem ipsum dolor, sit amet consectetur adipisicing elit</h2>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum omnis voluptatem accusantium nemo perspiciatis delectus atque autem! Voluptatum tenetur beatae unde aperiam, repellat expedita consequatur! Officiis id consequatur atque doloremque!</p>
                        <p> Nobis minus voluptatibus pariatur dignissimos libero quaerat iure expedita at? Asperiores nemo possimus nesciunt dicta veniam aspernatur quam mollitia.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='container py-5'>
            <div className='row'>
                <div className='col text-center'>
                    <h1><strong>Meet Our Team</strong></h1>
                    <p >At a assumenda quas cum earum ut itaque commodi saepe rem aspernatur quam natus quis <br /> nihil quod, hic explicabo doloribus magnam neque, exercitationem eius sunt!</p>
                </div>
            </div>
            <div className='row mt-3 text-center'>
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
                <Team name='Mr. Jack Hurry' image={person} work='Web Developer' />
            </div>
        </div>
        <Footer />
        <Copyright />
    </div>
  )
}
