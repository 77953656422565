import React from 'react'

import iphone from '../../assets/frontend/img/services/iphone-mockup.png'

export const Services = () => {
  return (
    <div>
        <div className='container py-5'>
            <div className='row text-center'>
                <div className='col'>
                    <div style={{ maxWidth: '600px' }} className='mx-auto'>
                        <h1><strong>What We Offer</strong></h1>
                        <p>Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi exercitationem quasi. In deleniti eaque aut repudiandae et a id nisi.</p>
                    </div>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-md-4 pe-md-5 text-center text-md-end'>
                    <div className='mt-md-5 pt-md-5'>
                        <h3>Marketing</h3>
                        <p>Plan it, create it, launch it. Collaborate seamlessly with all the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div className='mt-5'>
                        <h3>Marketing</h3>
                        <p>Plan it, create it, launch it. Collaborate seamlessly with all the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div className='mt-5'>
                        <h3>Marketing</h3>
                        <p>Plan it, create it, launch it. Collaborate seamlessly with all the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                </div>
                <div className='col-md-4'>
                    <img className='img-fluid' src={ iphone } alt='services' />
                </div>
                <div className='col-md-4 text-center text-sm-start ps-md-5'>
                    <div className='mt-4 mt-md-5 pt-md-5'>
                        <h3>Marketing</h3>
                        <p>Plan it, create it, launch it. Collaborate seamlessly with all the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div className='mt-5'>
                        <h3>Marketing</h3>
                        <p>Plan it, create it, launch it. Collaborate seamlessly with all the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                    <div className='mt-5'>
                        <h3>Marketing</h3>
                        <p>Plan it, create it, launch it. Collaborate seamlessly with all the organization and hit your marketing goals every month with our marketing plan.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}