import React from 'react'
import { Copyright } from '../../layouts/frontend/Copyright'
import { Footer } from '../../layouts/frontend/Footer'
import Navbar from '../../layouts/frontend/Navbar'
import { Services } from './Services'
import { SubHeader } from './SubHeader'

export const OurServices = () => {
  return (
    <div>
        <Navbar />
        <SubHeader title='Our Services' />
        <Services />
        <Footer />
        <Copyright />
    </div>
  )
}
