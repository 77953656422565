import React from 'react'

export const Header = () => {
  return (
    <div className="header-section">
        <div className="text-center text-light">
            <div className='header-content'>
                <div className='header-content-position text-center'>
                  <h1 className='fw-bold'>Hello there</h1>
                  <h4>Provident cupiditate voluptatem et in. Quaerat fugiat ut <br />assumenda excepturi exercitationem quasi. In deleniti eaque
                    aut<br />repudiandae et a id nisi.</h4>
                  <button className="mt-2 btn btn-primary"><strong>GET STARTED</strong></button>
                </div>
            </div>
        </div>
    </div>
  )
}
