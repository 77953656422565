import React from 'react'

export const Feature = (props) => {
  return (
    <div className='col-md-4'>
        <img src={ props.icon } alt={props.title} />
        <h3>{ props.title }</h3>
        <p>{ props.description }</p>
    </div>
  )
}
