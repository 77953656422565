import React from 'react'
import { Copyright } from '../../layouts/frontend/Copyright'
import { Footer } from '../../layouts/frontend/Footer'
import Navbar from '../../layouts/frontend/Navbar'
import { SubHeader } from './SubHeader'
import location from '../../assets/frontend/icons/loc.svg'
import envelope from '../../assets/frontend/icons/env.svg'
import phone from '../../assets/frontend/icons/phn.svg'
import talk from '../../assets/frontend/img/common/doodle.svg'

export const Contact = () => {
  return (
    <div>
        <Navbar />
        <SubHeader title='Contact Us' />
        <div className='container my-5' style={{ background: '#F8FAFC', borderRadius: '5px' }}>
            <div className='p-5'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h1 className='mb-4'><strong>Let's talk!</strong></h1>
                        <p className='text-muted'>Fill in the form to start a conversation.</p>
                        <div className='contact ms-md-2'>
                            <p className='mt-3'>
                                <img src={location} alt='location' /> Mirpur, Dhaka, Bangladesh
                            </p>
                            <p>
                                <img src={envelope} alt='envelope' /> info.test@gmail.com
                            </p>
                            <p>
                                <img src={phone} alt='phone' /> 017XXXXXXXXX
                            </p>
                        </div>
                        <div className='text-center'>
                            <img className='img-fluid' src={talk} alt='Lets Talk' style={{width: '200px'}} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <form className='mt-4'>
                            <div>
                                <label>Name:</label>
                                <input type='text' className='form-control border-0' style={{ background: '#F1F5F9', padding: '10px 5px', borderRadius: 'none' }} />
                            </div>
                            <div className='my-4'>
                                <label>Email:</label>
                                <input type='email' className='form-control border-0' style={{ background: '#F1F5F9', padding: '10px 5px', borderRadius: 'none' }} />
                            </div>
                            <div>
                                <label>Message:</label>
                                <textarea className='form-control border-0' style={{ background: '#F1F5F9', padding: '10px 5px', borderRadius: 'none' }}>
                                </textarea>
                            </div>
                            <div className='d-grid mt-5'>
                                <button className='btn btn-dark'><strong>SEND MESSAGE</strong></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Copyright />
    </div>
  )
}
