import React from 'react'
import { Achivement } from './card/Achivement'

export const Achievements = () => {
  return (
    <div className='bg-color-b text-center'>
        <div className='container py-5'>
            <div className='row'>
              <div className='col'>
                <h1><strong>Increase More Traffic Your Business?</strong></h1>
                <p>Credibly redefine high-payoff web services after holistic experiences. Globally harness <br /> multidisciplinary solutions vis-a-vis intuitive customer service.</p>
              </div>
            </div>
            <div className='row mt-4'>
              <Achivement total='50+' title='Clients' />
              <Achivement total='10K' title='Social Flowerss' />
              <Achivement total='2+' title='Years Experience' />
              <Achivement total='1' title='Award' />
              <Achivement total='10+' title='IT Professionals' />
              <Achivement total='20+' title='Worked Project' />
            </div>
        </div>
    </div>
  )
}
