import React from 'react'
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-light shadow sticky-top">
            <div className="container">
                <Link className="navbar-brand" to="/"><strong style={{fontSize: '23px'}}>GeniousHubBD</strong></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item text-dark">
                    <Link className="nav-link" aria-current="page" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/service">Services</Link>
                    </li>
                </ul>
                <form className="d-flex" role="search">
                    <Link className="btn btn-dark" to='/contact'>Contact</Link>
                </form>
                </div>
            </div>
        </nav>
    );
}

export default Navbar
