import React from 'react'

export const SubHeader = (props) => {
  return (
    <div class="sub-header text-center text-light">
		<div class="container">
			<div class="row">
				<div class="col mt-4 pt-5">
					<h1><strong>{props.title}</strong></h1>
                    <p>{props.designation}</p>
				</div>
			</div>
		</div>
	</div>
  )
}
