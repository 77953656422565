import React from 'react'
import { Step } from './col/Step'

export const Setps = () => {
  return (
    <div className='bg-color-b text-center py-5'>
        <div className='container'>
            <div className='row'>
                <div className='col'>
                    <p className='mb-0'>HOW WE WORK</p>
                    <h1><strong>Our working procedure is simple</strong></h1>
                </div>
            </div>
            <div className='row row-cols-1 row-cols-md-3 g-4 mt-4 text-center'>
                <Step id='1' title='Developing an effective strategy for better quality product.' />
                <Step id='2' title='Coding we start coding after proper planning then make clean code' />
                <Step id='3' title='Testing We provide automated testing and after production support' />
            </div>
        </div>
    </div>
  )
}
