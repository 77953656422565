import React from 'react'
import facebook from '../../assets/frontend/icons/facebook.svg'
import instagram from '../../assets/frontend/icons/instagram.svg'
import twitter from '../../assets/frontend/icons/twitter.svg'
import linkedin from '../../assets/frontend/icons/linkedin.svg'
import location from '../../assets/frontend/icons/location-dot-solid.svg'
import envelope from '../../assets/frontend/icons/envelope-solid.svg'
import phone from '../../assets/frontend/icons/phone-solid.svg'
import home from '../../assets/frontend/icons/house-solid.svg'
import about from '../../assets/frontend/icons/circle-info-solid.svg'
import service from '../../assets/frontend/icons/servicestack.svg'
import contact from '../../assets/frontend/icons/address-card-regular.svg'

export const Footer = () => {
  return (
    <div className='py-5 color-b bg-color-c'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-3'>
                    <div className='card bg-color-c border-0'>
                        <div className='card-body'>
                            <h4>GeniusHubBD</h4>
                            <p className='mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='card bg-color-c border-0'>
                        <div className='card-body'>
                            <div className='footer-nav'>
                                <h4>geniushubbd.com</h4>
                                <p className='mt-3'>
                                    <img src={home} alt='home' /> Home
                                </p>
                                <p>
                                    <img src={about} alt='about' /> About
                                </p>
                                <p>
                                    <img src={service} alt='service' /> Services
                                </p>
                                <p>
                                    <img src={contact} alt='contact' /> Contact
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='card bg-color-c border-0'>
                        <div className='card-body'>
                            <h4>Connect with us</h4>
                            <div className='social'>
                                <p className='mt-3'>
                                    <img src={facebook} alt='facebook' /> Facbook
                                </p>
                                <p>
                                    <img src={instagram} alt='instagram' /> Instagram
                                </p>
                                <p>
                                    <img src={twitter} alt='twitter' /> Twitter
                                </p>
                                <p>
                                    <img src={linkedin} alt='linkedin' /> LinkedIn
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='card bg-color-c border-0'>
                        <div className='card-body'>
                            <div className='contact'>
                                <h4>Contact us</h4>
                                <p className='mt-3'>
                                    <img src={location} alt='location' /> Mirpur, Dhaka, Bangladesh
                                </p>
                                <p>
                                    <img src={envelope} alt='envelope' /> info.test@gmail.com
                                </p>
                                <p>
                                    <img src={phone} alt='phone' /> 017XXXXXXXXX
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
