import React from 'react'

export const Team = (props) => {
  return (
    <div className='col-md-3 py-3 text-center text-muted'>
        <div className="card border-0" style={{ width: '12rem' }}>
            <img src={props.image} className="card-img-top" alt={props.name} />
            <h4 className='mt-2'>{props.name}</h4>
            <div className='text-dar text-muted'>{props.work}</div>
        </div>
    </div>
  )
}
